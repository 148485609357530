
import { defineComponent } from 'vue'
import { Sunny, Moon } from '@element-plus/icons-vue'

export default defineComponent({
  setup() {
    return { Sunny, Moon }
  },

  data: () => ({
    isDarkMode: false,
  }),

  watch: {
    isDarkMode(newValue) {
      if (newValue) {
        document.documentElement.classList.add('dark')
        this.setPreferredMode()
      } else {
        document.documentElement.classList.remove('dark')
        this.setPreferredMode()
      }
    }
  },

  created() {
    this.getPreferredMode()
  },

  methods: {
    toggleDarkMode() {
      this.isDarkMode = !this.isDarkMode
    },

    getPreferredMode() {
      this.isDarkMode = localStorage.getItem('darkMode') === 'true'
    },

    setPreferredMode() {
      localStorage.setItem('darkMode', this.isDarkMode ? 'true' : 'false')
    }
  }
})
