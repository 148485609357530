
import { defineComponent } from 'vue'
import { Check, Close, Edit, ShoppingCart } from '@element-plus/icons-vue'

export default defineComponent({
  props: {
    employee: {
      type: Object,
      required: true,
    },
  },

  components: {
    Check,
    Close,
    Edit,
    ShoppingCart,
  },

  methods: {
    handleToggleActiveState() {
      this.$emit('toggle-active-state', this.employee.id, this.employee.active)
    },

    handleEditClick() {
      this.$emit('toggle-edit-dialog', this.employee)
    },
  }
})
