import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_alert = _resolveComponent("el-alert")!
  const _component_employee_card = _resolveComponent("employee-card")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_employee_form = _resolveComponent("employee-form")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.error === 403)
      ? (_openBlock(), _createBlock(_component_el_alert, {
          key: 0,
          title: "Keine Rechte zur Anzeige dieses Bereiches!",
          type: "error",
          "show-icon": ""
        }))
      : _createCommentVNode("", true),
    (!_ctx.error)
      ? (_openBlock(), _createBlock(_component_el_row, {
          key: 1,
          gutter: 20
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.employeeData.elements, (employee) => {
              return (_openBlock(), _createBlock(_component_el_col, {
                md: 4,
                key: employee.id
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_employee_card, {
                    employee: employee,
                    onToggleActiveState: _ctx.toggleAccount,
                    onToggleEditDialog: _ctx.toggleDialog
                  }, null, 8, ["employee", "onToggleActiveState", "onToggleEditDialog"])
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_el_dialog, {
      fullscreen: "",
      modelValue: _ctx.dialogVisible,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dialogVisible) = $event)),
      title: "Mitarbeiter bearbeiten"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_employee_form, {
          onCloseDialog: _ctx.toggleDialog,
          employee: _ctx.employee,
          onReloadList: _ctx.getEmployeeList
        }, null, 8, ["onCloseDialog", "employee", "onReloadList"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}