
import { defineComponent } from 'vue'
import { CirclePlusFilled } from '@element-plus/icons-vue'
import EmployeeForm from '@/components/employee/EmployeeForm.vue'

export default defineComponent({
  components: {
    CirclePlusFilled,
    EmployeeForm
  },

  data: () => ({
    dialogVisible: false,
  }),

  methods: {
    closeDialog() {
      console.log('close dialog')
      this.dialogVisible = false
    }
  }
})
