
import { defineComponent } from 'vue'
import { invokePost } from '@shopware-pwa/shopware-6-client'
import { ElLoading } from 'element-plus'
import EmployeeCard from '@/components/employee/EmployeeCard.vue'
import EmployeeForm from '@/components/employee/EmployeeForm.vue'

export interface generalMultiFilter {
  type: string
  operator: string
  queries: generalFilter[]
}

export interface generalFilter {
  type: string
  field: string
  value: string
}

export default defineComponent({
  props: {
    filter: {
      type: String,
      default: () => null,
    },
  },

  components:{
    EmployeeCard,
    EmployeeForm
  },

  data: () => ({
    page: 1,
    limit: 30,
    employeeData: {},
    employee: {},
    error: null,
    query: [] as generalMultiFilter[],
    dialogVisible: false
  }),

  mounted() {
    this.getEmployeeList()
  },

  watch: {
    filter(newValue) {
      if(newValue) {
        this.query = [{
          type: 'multi',
          operator: 'or',
          queries: [
            {
              type: 'contains',
              field: 'lastName',
              value: newValue,
            },
            {
              type: 'contains',
              field: 'firstName',
              value: newValue,
            },
            {
              type: 'contains',
              field: 'email',
              value: newValue,
            },
          ]
        }]
      } else {
        this.query = []
      }

      this.getEmployeeList()
    },
  },

  methods: {
    async getEmployeeList() {
      const loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })

      this.error = null;

      let filter = this.filter.length > 0 ? this.filter : null

      try {
        const response = await invokePost({ address: '/store-api/employees', payload: { page: this.page, limit: this.limit, filter: this.query }});

        this.employeeData = response.data;

        loading.close()
      } catch (error: any) {
        this.error = error.statusCode

        loading.close()
      }
    },

    async toggleAccount(id: string, active: boolean) {
      const loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })

      try {
        if (!active) {
          await invokePost({address: '/store-api/employee/activate', payload: { customerId: id }});
        } else {
          await invokePost({address: '/store-api/employee/deactivate', payload: { customerId: id }});
        }

        await this.getEmployeeList()

        loading.close()
      } catch (error: any) {
        this.error = error.statusCode

        loading.close()
      }
    },

    toggleDialog(employee = {}) {
      this.employee = employee
      this.dialogVisible = !this.dialogVisible
    },
  }
})
