import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import 'element-plus/theme-chalk/dark/css-vars.css'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import { setup } from '@shopware-pwa/shopware-6-client';

setup({
    endpoint: process.env.VUE_APP_REQUEST_URL,
    accessToken: process.env.VUE_APP_SW_ACCESS_KEY,
    contextToken: 'empty'
});

createApp(App).use(store).use(router).use(ElementPlus).mount('#app')
