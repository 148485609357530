import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_switch = _resolveComponent("el-switch")!

  return (_openBlock(), _createBlock(_component_el_switch, {
    "active-color": "#333333",
    "inline-prompt": "",
    modelValue: _ctx.isDarkMode,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isDarkMode) = $event)),
    "active-icon": _ctx.Sunny,
    "inactive-icon": _ctx.Moon
  }, null, 8, ["modelValue", "active-icon", "inactive-icon"]))
}