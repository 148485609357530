
import { defineComponent, reactive, ref } from 'vue'
import { FormInstance, FormRules } from 'element-plus'
import { invokePost, getAvailableCountries, getAvailableSalutations } from '@shopware-pwa/shopware-6-client'

export default defineComponent({
  props: {
    employee: {
      type: Object,
      default: () => ({})
    }
  },

  setup(props) {
    const employeeFormRef = ref<FormInstance>()

    const employeeForm = reactive({
      salutationId: '',
      title: '',
      firstName: '',
      lastName: '',
      personnelNumber: '',
      username: '',
      password: '',
      company: '',
      department: '',
      street: '',
      city: '',
      zipcode: '',
      shippingStreet: '',
      shippingCity: '',
      shippingZipcode: '',
      countryId: '',
      guest: false,
    })

    const rules = reactive<FormRules>({
      salutationId: [
        { required: true, message: 'Bitte wählen Sie eine Anrede aus.' }
      ],
      username: [
        { required: true, message: 'Bitte geben Sie eine E-Mail-Adresse ein.' }
      ],
      password: [
        { required: Object.keys(props.employee).length <= 0, message: 'Bitte geben Sie ein Passwort ein.' }
      ],
      firstName: [
        { required: true, message: 'Bitte geben Sie einen Vornamen ein.' }
      ],
      lastName: [
        { required: true, message: 'Bitte geben Sie einen Nachnamen ein.' }
      ],
      street: [
        { required: true, message: 'Bitte geben Sie eine Straße und Hausnummer ein.' }
      ],
      city: [
        { required: true, message: 'Bitte geben Sie einen Ort ein.' }
      ],
      zipcode: [
        { required: true, message: 'Bitte geben Sie eine Postleitzahl ein.' }
      ],
      countryId: [
        { required: true, message: 'Bitte wählen Sie ein Land.' }
      ],
    })

    return {
      employeeFormRef,
      employeeForm,
      rules
    }
  },

  data: () =>({
    loading: false,
    error: null,
    data: {},
    salutationOptions: [],
    countryOptions: [],
  }),

  mounted() {
    this.init()
  },

  watch: {
    employee() {
      this.init()
    }
  },

  methods: {
    async init() {
      this.salutationOptions = await getAvailableSalutations();
      this.countryOptions = await getAvailableCountries();

      if (Object.keys(this.employee).length > 0) {
        this.employeeForm.salutationId = this.employee.salutationId
        this.employeeForm.firstName = this.employee.firstName
        this.employeeForm.lastName = this.employee.lastName
        this.employeeForm.username = this.employee.email
        this.employeeForm.personnelNumber = this.employee.extensions?.customerExtension?.personnelNumber
        this.employeeForm.street = this.employee.defaultBillingAddress.street
        this.employeeForm.city = this.employee.defaultBillingAddress.city
        this.employeeForm.zipcode = this.employee.defaultBillingAddress.zipcode
        this.employeeForm.countryId = this.employee.defaultBillingAddress.countryId
        this.employeeForm.shippingStreet = this.employee.defaultShippingAddress.street
        this.employeeForm.shippingCity = this.employee.defaultShippingAddress.city
        this.employeeForm.shippingZipcode = this.employee.defaultShippingAddress.zipcode
      }
    },

    async handleSubmit(formEl: FormInstance | undefined) {
      if (!formEl) return

      this.loading = true

      await formEl.validate((valid, fields) => {
        if (valid) {
          this.loading = false
        }
      })

      this.transferData();

      if (Object.keys(this.employee).length > 0) {
        await this.handleProfileUpdate()
      } else {
        await this.handleRegisterCustomer(formEl)
      }

      this.$emit('reload-list')

      this.loading = false

      this.$emit('close-dialog')
    },

    async handleProfileUpdate() {
      try {
        await invokePost({ address: '/store-api/employee/change-profile', payload: this.data })
        return
      } catch (error) {
        console.log(error)
      }
    },

    async handleRegisterCustomer(formEl: FormInstance) {
      try {
        await invokePost({ address: '/store-api/employee/register', payload: this.data })

        formEl.resetFields()

        return
      } catch (error) {
        console.log(error)
      }
    },

    handleCancel(formEl: FormInstance | undefined) {
      if (formEl) formEl.resetFields()

      this.$emit('close-dialog')
    },

    transferData() {
      this.data = {
        id: this.employee.id,
        salutationId: this.employeeForm.salutationId,
        firstName: this.employeeForm.firstName,
        lastName: this.employeeForm.lastName,
        email: this.employeeForm.username,
        password: this.employeeForm.password,
        customerExtensionId: this.employee.extensions?.customerExtension?.id,
        personnelNumber: this.employeeForm.personnelNumber,
        billingAddress: {
          id: Object.keys(this.employee).length > 0 ? this.employee.defaultBillingAddress.id : null,
          salutationId: this.employeeForm.salutationId,
          firstName: this.employeeForm.firstName,
          lastName: this.employeeForm.lastName,
          email: this.employeeForm.username,
          street: this.employeeForm.street,
          city: this.employeeForm.city,
          zipcode: this.employeeForm.zipcode,
          countryId: this.employeeForm.countryId,
        },
        shippingAddress: {
          id: Object.keys(this.employee).length > 0 ? this.employee.defaultShippingAddress.id : null,
          salutationId: this.employeeForm.salutationId,
          firstName: this.employeeForm.firstName,
          lastName: this.employeeForm.lastName,
          email: this.employeeForm.username,
          street: this.employeeForm.shippingStreet,
          city: this.employeeForm.shippingCity,
          zipcode: this.employeeForm.shippingZipcode,
          countryId: this.employeeForm.countryId,
        },
      }
    }
  }
})
