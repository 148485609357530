import { login, logout, config } from '@shopware-pwa/shopware-6-client'

const state = {
    user: {},
    isLoggedIn: false,
    isLoading: false,
    hasError: false,
    errorStatusCode: 0,
}

const getters = {
    user: (state: any)  => state.user,
    isLoggedIn: (state: any) => state.isLoggedIn,
    isLoading: (state: any) => state.isLoading,
    hasError: (state: any) => state.hasError,
    errorStatusCode: (state: any) => state.errorStatusCode,
}

const actions = {
    async login({ commit }: any, user: any) {
        commit('setLoading', true)

        try {
            await login({username: user.username, password: user.password})
            commit('setIsLoggedIn', true)
            commit('setLoading', false)
        } catch (error: any) {
            commit('setLoading', false)
            commit('setError', { hasError: true, errorStatusCode: error.statusCode })
        }
    },

    async logout({ commit }: any) {
        commit('setLoading', true)

        try {
            await logout()
            commit('resetContextToken', 'empty')
            commit('setUser', {})
            commit('setIsLoggedIn', false)
            commit('setLoading', false)
        } catch (error) {
            console.log(error)
            commit('setLoading', false)
        }
    }
}

const mutations = {
    setLoading(state: any, isLoading: boolean) {
        state.isLoading = isLoading
    },

    setUser(state: any, user: any) {
        state.user = user
    },

    resetContextToken(state: any, contextToken: string) {
        config.contextToken = contextToken
    },

    setIsLoggedIn(state: any, isLoggedIn: any) {
        state.isLoggedIn = isLoggedIn
    },

    setError(state: any, error: any) {
        state.hasError = error.hasError
        state.errorStatusCode = error.errorStatusCode
    }
}

export const user = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}