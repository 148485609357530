<template>
  <div class="common-layout">
    <el-header>
      <nav>
        <el-menu mode="horizontal" :router="true" :ellipsis="false" class="align-items-center">
          DELI VERY WORKFOOD
          <div class="flex-grow" />
          <el-menu-item index="/">Mitarbeiter</el-menu-item>
          <el-button @click="handleLogoutClick()" v-if="isLoggedIn">Logout</el-button>
          <el-divider direction="vertical" border-style="dashed" />
          <toggle-dark-mode />
        </el-menu>
      </nav>
    </el-header>

    <el-main>
      <router-view/>
    </el-main>

    <update-dialog />
  </div>
</template>

<style lang="scss">
html {
  font-family: 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB',
  'Microsoft YaHei', '微软雅黑', Arial, sans-serif;
}

.flex-grow {
  flex-grow: 1;
}
</style>
<script>
import ToggleDarkMode from '@/components/app/ToggleDarkMode'
import UpdateDialog from '@/components/app/UpdateDialog'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: { ToggleDarkMode, UpdateDialog },

  computed: {
    ...mapGetters('user', ['isLoading', 'isLoggedIn'])
  },

  methods: {
    ...mapActions('user', ['logout']),

    async handleLogoutClick() {
      await this.logout()
      this.$router.push('login')
    },
  },
}
</script>

<style>
.align-items-center {
  align-items: center;
}
</style>