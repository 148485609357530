
import { defineComponent, reactive } from 'vue'
import { Search } from '@element-plus/icons-vue'

export default defineComponent({
  setup() {
    const query = reactive({
      search: '',
    })

    return {
      query,
      Search
    }
  },

  methods: {
    handleSearch() {
      this.$emit('on-search', this.query.search)
    },

    handleClear() {
      this.$emit('on-search', '')
    }
  },
})
