import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "search-bar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_input = _resolveComponent("el-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_input, {
      modelValue: _ctx.query.search,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.query.search) = $event)),
      placeholder: "E-Mail oder Nachname",
      class: "input-with-select",
      clearable: "",
      onClear: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleClear()))
    }, {
      append: _withCtx(() => [
        _createVNode(_component_el_button, {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleSearch())),
          icon: _ctx.Search
        }, null, 8, ["icon"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}