
import { defineComponent } from 'vue'

export default defineComponent({
  data: () => ({
    registration: null as any,
    updateExists: false,
    refreshing: false,
  }),

  created() {
    document.addEventListener('appUpdated', this.showRefreshUI, true)

    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.refreshing) return
      this.refreshing = true
      window.location.reload()
    });
  },

  methods: {
    showRefreshUI(e: any) {
      this.registration = e.detail;
      this.updateExists = true;
    },

    handleAppRefresh() {
      this.updateExists = false
      if (!this.registration || !this.registration.waiting) {
        return;
      }
      this.registration.waiting.postMessage('skipWaiting');
    }
  },
})
