
import { defineComponent } from 'vue';
import EmployeeList from '@/components/employee/EmployeeList.vue'
import EmployeeCreate from "@/components/employee/EmployeeCreate.vue";
import SearchBar from "@/components/app/SearchBar.vue";

export default defineComponent({
  name: 'HomeView',

  components: {
    EmployeeCreate,
    EmployeeList,
    SearchBar
  },

  data: () => ({
    filter: '',
  }),

  methods: {
    filterItems(search: string) {
      console.log('searching for', search)

      this.filter = search
    },
  }
});
